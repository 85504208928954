<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showDeletePopup=true" />
    <vs-prompt
      title="Delete Property"
      accept-text="Delete"
      @accept="onDeleteProperty"
      :active.sync="showDeletePopup">

      <div class="vx-row mt-2 mb-2">
        <div class="vx-col w-full">
          <div class="">Are you sure you want to delete the property - {{params.data.propertyName}}</div>
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onEdit () {
      this.params.context.componentParent.editProperty(this.params.data.id) 
    },
    onDeleteProperty () {
      this.params.context.componentParent.deleteProperty(this.params.data.id) 
    }
  }
}
</script>
