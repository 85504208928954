<template>
  <travio-center-container grid-width='2/3'>
    <h2 style="color:#636363" class="mb-2">Property Content List</h2>
    <p class="mb-4">If you are using the contracting engine, please <strong>do not</strong> add your own properties as they will not be linked. Please wait for your properties to appear automatically during the sync between platforms.</p>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button @click="addProperty" class="mr-4 sm:mb-0 mb-2">
            Add
          </vs-button>
          <vs-input
            class="float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
        </div>
      </div>
       <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="propertyContentList"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        rowSelection="single"
        :pagination="true"
        :context="context"
        :suppressPaginationPanel="false"
      >
      </ag-grid-vue>
      
      
    </vx-card>
  
  </travio-center-container>
</template>

<script>
    
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Vue from "vue"
import CellRendererPropertyActions from './cell-renderers/CellRendererPropertyActions.vue'
import CellRendererActiveCheckbox from './cell-renderers/CellRendererActiveCheckbox.vue'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
    AgGridVue,
    CellRendererPropertyActions,
    CellRendererActiveCheckbox
  },
  props: {
    applicationId: { required: true}
  },
  data () {
    return {
      propertyContentList: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererPropertyActions,
        CellRendererActiveCheckbox
      },
      context: null,
      searchQuery: ''
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('customproperties')) {
      this.$router.push('/error-404')
    }
    // this.propertyContentList = [
    //   { id: 1, propertyReference: '123', propertyName: 'The New World', rating: '1.0', location: 'Cala Millor, ES', active: true },
    //   { id: 2, propertyReference: '123', propertyName: 'Property 2', rating: '1.0', location: 'Paris, FR', active: false },
    // ]
    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/customProperties/apps/${this.applicationId}`)
      this.propertyContentList = response.data

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  beforeMount() {

    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererPropertyActions) },
      { field: 'id', hide: true },
      { headerName: 'Reference', field: 'propertyReference', width: 180,  suppressSizeToFit: true },
      { headerName: 'Property Name', field: 'propertyName', width: 280,  suppressSizeToFit: false },
      { headerName: 'Location', field: 'location', width: 200,  suppressSizeToFit: false },
      { headerName: 'Rating', field: 'rating', width: 120,  suppressSizeToFit: false },
      { headerName: 'Active', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererActiveCheckbox) },
      { field: 'contentText', hide: true },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 60,
      sortable: true,
      resizable: true,
      suppressMenu: true
    }

    this.autoGroupColumnDef = { minWidth: 200 };
    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    addProperty () {
      this.$router.push({ name: 'application-tools-property-content-add', 
        params: { 
          applicationId: this.applicationId,
        }
      })
    },
    editProperty (propId) {
      this.$router.push({ name: 'application-tools-property-content-edit', 
        params: { 
          applicationId: this.applicationId,
          propertyId: propId
        }
      })
    },
    async deleteProperty (propId) {
      this.$vs.loading()
      try {
        const response = await this.$http.delete(`api/customProperties/apps/${this.applicationId}/${propId}`)
        this.propertyContentList = this.propertyContentList.filter(x => x.id != propId)
        this.$_notifySuccess('Successfully deleted property');

      } catch (error) {
        this.$_notifyFailure(error)
      }

      this.$vs.loading.close();
    },
  }
}
</script>

<style>

</style>
