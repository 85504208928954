<template>
  <div class="flex cell-renderer-property-checkbox-container">
    <vs-checkbox v-model="params.data.active" disabled />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
/* Make sure the checkbox centers vertically in the row*/
.cell-renderer-property-checkbox-container {
  height: inherit;
}
</style>